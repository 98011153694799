import React from 'react'
import './style.scss'
import { Parallax } from 'react-scroll-parallax';
import { useInView } from 'react-intersection-observer'
import Intro from '../../../animations/Intro';

function CustomBlock({ speed, children }) {
  const [io, ioInView] = useInView({ triggerOnce: true })

  return (
    <div className={`parallax-copy span-12-mobile flex ac-center ai-center copy`}>
      <Parallax speed={speed}>
        <div ref={io} style={{ flexWrap: 'wrap' }} className='description padd w-100'>
          <Intro visible={ioInView} in={{ fade: 400 }} delayIn={750} mounted={true} stay={true} className={``}>
            {children}
          </Intro>
        </div>
      </Parallax>
    </div>
  )
}

export default CustomBlock