import React from 'react'
import './index.scss'

import Seo from '../components/seo'

import MainFrameCover from '../components/MainFrameCover'
import Intro from '../animations/Intro'
import { useInView } from 'react-intersection-observer'

import { GatsbyImage } from 'gatsby-plugin-image'
import Footer from '../components/Footer'
import { graphql } from 'gatsby'
import ACFOverlay from '../components/Content/ACF/Overlay'
import { Link } from 'gatsby'
import ParallaxSlider from '../components/Blocks/ParallaxSlider'
import ParallaxCopy from '../components/Blocks/ParallaxCopy'

function Page(props) {
  const { data } = props
  const speed = [20, 15, 14, 13, 16, 19, 12, 19, 15, 12, 11, 19]

  const [refHero, refHeroInView] = useInView({ triggerOnce: true })
  const [refAbout, refAboutInView] = useInView({ triggerOnce: true })

  return (
    <>

      <MainFrameCover />

      <main id="mainframe" className={`active main-home`}>

        <Seo title={"Meble ze stali na wymiar, drzwi loftowe"} siteTitle={'Stalowa Rączka'} description={"Drzwi, Ścianki, Balustrady, Ogrodzenia, Meble, Tarasy. Kompleksowe rozwiązania przestrzeni loft. Indywidualne projekty na zamówienie."} path={props.location?.pathname ? props.location?.pathname : ''} />

        <section ref={refHero} className="section-hero-new x1 t">

          <Intro visible={refHeroInView} in={{ fadeTop: 500 }} delayIn={0} mounted={true} stay={true} className={``}>
            <div className="aspect-ratio">
              <GatsbyImage image={data.imgHero?.childImageSharp?.gatsbyImageData} alt={"parallax-img"} />
            </div>
          </Intro>

          <div className='heading-container padd-2'>
            <Intro visible={refHeroInView} in={{ fadeBottom: 750 }} delayIn={500} mounted={true} stay={true} className={``}>
              <h1 className='heading'>Stalowa <br /> Rączka</h1>
            </Intro>
          </div>

          <ACFOverlay bgOverlay={0.8} />

        </section>

        <section ref={refAbout} className="section-about-new padd flex-12 c5 jc-flex-end">
          <div className='span-8 span-12-mobile'>
            <Intro visible={refAboutInView} in={{ fade: 750 }} delayIn={500} mounted={true} stay={true} className={``}>
              <div className="content">
                <h2>Nasza specjalność</h2>
                <p>Jesteśmy specjalistami w tworzeniu eleganckich i trwałych mebli loftowych oraz elementów metalowych. Nasze projekty opierają się na naturalnych materiałach, takich jak stal, aluminium, mosiądz, szkło i drewno, które zapewniają nie tylko trwałość, ale również niepowtarzalny charakter i styl. Dostosowujemy się do indywidualnych potrzeb i oczekiwań naszych klientów, projektując i tworząc meble na zamówienie. Każdy mebel jest ręcznie wykonany z dbałością o najdrobniejsze detale, aby zapewnić długotrwałą jakość i funkcjonalność.</p>
                <p>Jeśli szukasz wyjątkowych mebli loftowych, które będą służyć Ci przez wiele lat, jesteśmy tutaj, aby Ci pomóc. Skontaktuj się z nami, aby uzyskać więcej informacji i zamówić swoje wymarzone meble loftowe.</p>
              </div>
            </Intro>
          </div>
        </section>

        <section className="section-products c5">
          <div className='parallax-block-sections padd-bottom-4 padd-top-4'>

            <div className="flex-section" >
              <ParallaxSlider images={[data.imgDrzwi4, data.imgDrzwi2, data.imgDrzwi3, data.imgDrzwi1]} speed={speed[0]} />
              <ParallaxCopy speed={speed[1]}>
                <h2>Drzwi Loft</h2>
                <p>
                  Wyróżnij swoje miejsce z naszymi drzwiami loftowymi.
                  Dostępne z ościeżnicą i bez, z możliwością otwierania i przesuwania.
                  Opcjonalne przeszklenia dodają blasku. Wszystko malowane proszkowo dla trwałości.
                  Skontaktuj się z nami i wybierz swój wyjątkowy styl.
                </p>
                <Link className="btn arrow" to={`/realizacje/drzwi`}>
                  <span>Zobacz</span>
                </Link>
              </ParallaxCopy>
            </div>

            <div className="flex-section" >
              <ParallaxCopy speed={speed[2]}>
                <h2>Ścianki</h2>
                <p>
                  Stwórz funkcjonalną przestrzeń z naszymi ściankami działowymi.
                  Dostosowujemy je do Twoich potrzeb, oferując elementy stałe i ruchome, a także opcjonalne przeszklenia.
                  Wszystko malowane proszkowo dla trwałości. Skontaktuj się z nami i zacznij projektować swoją przestrzeń.
                </p>
                <Link className="btn arrow" to={`/realizacje/scianki`}>
                  <span>Zobacz</span>
                </Link>
              </ParallaxCopy>
              <ParallaxSlider images={[data.imgScianki1, data.imgScianki2, data.imgScianki3]} speed={speed[3]} />
            </div>

            <div className="flex-section" >
              <ParallaxSlider images={[data.imgBalustrady1, data.imgBalustrady2, data.imgBalustrady3, data.imgBalustrady4]} speed={speed[4]} />
              <ParallaxCopy speed={speed[5]}>
                <h2>Balustrady</h2>
                <p>
                  Dodaj blask swojej przestrzeni dzięki naszym balustradom wewnętrznym i zewnętrznym.
                  Oferujemy ocynkowanie, aby zapewnić trwałość. Dostosujemy wzór i wymiar do Twoich potrzeb.
                  Skontaktuj się z nami i uzyskaj swoją wymarzoną balustradę.
                </p>
                <Link className="btn arrow" to={`/realizacje/balustrady`}>
                  <span>Zobacz</span>
                </Link>
              </ParallaxCopy>
            </div>

            <div className="flex-section" >
              <ParallaxCopy speed={speed[6]}>
                <h2>Bramy</h2>
                <p>
                  Stwórz swoją wizualną strefę prywatności z naszymi bramami i ogrodzeniami.
                  Dostosujemy je do Twoich preferencji, oferując opcję ocynkowania.
                  Wszystko malowane proszkowo dla trwałości.
                  Skontaktuj się z nami i zamów swoje niepowtarzalne bramy i ogrodzenia.
                </p>
                <Link className="btn arrow" to={`/realizacje/bramy`}>
                  <span>Zobacz</span>
                </Link>
              </ParallaxCopy>
              <ParallaxSlider images={[data.imgBramy1, data.imgBramy2, data.imgBramy3, data.imgBramy4]} speed={speed[7]} />

            </div>

            <div className="flex-section" >
              <ParallaxSlider images={[data.imgMeble1, data.imgMeble2, data.imgMeble3, data.imgMeble4]} speed={speed[8]} />

              <ParallaxCopy speed={speed[9]}>
                <h2>Meble</h2>
                <p>
                  Ożyw swoją przestrzeń z naszymi niepowtarzalnymi meblami loftowymi.
                  Oferta obejmuje ławy, stoły, toaletki, regały, biblioteki, lustra i wieszaki.
                  Wszystko wykonane z dbałością o szczegóły, z najlepszych materiałów.
                  Skontaktuj się z nami i wybierz swój wyjątkowy styl.
                </p>
                <Link className="btn arrow" to={`/realizacje/meble`}>
                  <span>Zobacz</span>
                </Link>
              </ParallaxCopy>
            </div>

            <div className="flex-section" >
              <ParallaxCopy speed={speed[10]}>
                <h2>Tarasy</h2>
                <p>
                  Stwórz swój własny raj na tarasie.
                  Nasze rozwiązania są dostosowywane do Twoich potrzeb i preferencji,
                  oferując niepowtarzalny styl i funkcjonalność.
                  Skontaktuj się z nami i zamów swój wymarzony taras.
                </p>
                <Link className="btn arrow" to={`/realizacje/tarasy`}>
                  <span>Zobacz</span>
                </Link>
              </ParallaxCopy>
              <ParallaxSlider images={[data.imgTarasy1, data.imgTarasy2, data.imgTarasy3]} speed={speed[11]} />
            </div>

            <div className='bg-1'></div>
          </div>
        </section>

        <Footer />

      </main>

    </>
  )
}

export default Page

export const query = graphql`
  query {
            imgHero: file(relativePath: {eq: "page-home/hero.jpg" }) {
              childImageSharp {
                gatsbyImageData(webpOptions: {quality: 70}, layout: CONSTRAINED	, formats: WEBP, breakpoints: [300, 500, 750, 1080, 1366, 1920], placeholder: DOMINANT_COLOR)		
              }
            } 

            imgDrzwi1: file(relativePath: {eq: "page-home/slider-drzwi/2.jpeg" }) {
              childImageSharp {
                gatsbyImageData(webpOptions: {quality: 100}, layout: CONSTRAINED	, formats: WEBP, breakpoints: [300, 500, 750, 1080, 1366, 1920], placeholder: DOMINANT_COLOR)		
              }
            }           
            imgDrzwi2: file(relativePath: {eq: "page-home/slider-drzwi/4.jpeg" }) {
              childImageSharp {
                gatsbyImageData(webpOptions: {quality: 100}, layout: CONSTRAINED	, formats: WEBP, breakpoints: [300, 500, 750, 1080, 1366, 1920], placeholder: DOMINANT_COLOR)		
              }
            }        
            imgDrzwi3: file(relativePath: {eq: "page-home/slider-drzwi/6.jpeg" }) {
              childImageSharp {
                gatsbyImageData(webpOptions: {quality: 100}, layout: CONSTRAINED	, formats: WEBP, breakpoints: [300, 500, 750, 1080, 1366, 1920], placeholder: DOMINANT_COLOR)		
              }
            }
            imgDrzwi4: file(relativePath: {eq: "page-home/slider-drzwi/7.jpeg" }) {
              childImageSharp {
                gatsbyImageData(webpOptions: {quality: 100}, layout: CONSTRAINED	, formats: WEBP, breakpoints: [300, 500, 750, 1080, 1366, 1920], placeholder: DOMINANT_COLOR)		
              }
            }

            imgScianki1: file(relativePath: {eq: "page-home/slider-scianki/1.jpeg" }) {
              childImageSharp {
                gatsbyImageData(webpOptions: {quality: 100}, layout: CONSTRAINED	, formats: WEBP, breakpoints: [300, 500, 750, 1080, 1366, 1920], placeholder: DOMINANT_COLOR)		
              }
            }           
            imgScianki2: file(relativePath: {eq: "page-home/slider-scianki/2.jpeg" }) {
              childImageSharp {
                gatsbyImageData(webpOptions: {quality: 100}, layout: CONSTRAINED	, formats: WEBP, breakpoints: [300, 500, 750, 1080, 1366, 1920], placeholder: DOMINANT_COLOR)		
              }
            }        
            imgScianki3: file(relativePath: {eq: "page-home/slider-scianki/3.jpeg" }) {
              childImageSharp {
                gatsbyImageData(webpOptions: {quality: 100}, layout: CONSTRAINED	, formats: WEBP, breakpoints: [300, 500, 750, 1080, 1366, 1920], placeholder: DOMINANT_COLOR)		
              }
            }

            imgBalustrady1: file(relativePath: {eq: "page-home/slider-balustrady/1.jpeg" }) {
              childImageSharp {
                gatsbyImageData(webpOptions: {quality: 100}, layout: CONSTRAINED	, formats: WEBP, breakpoints: [300, 500, 750, 1080, 1366, 1920], placeholder: DOMINANT_COLOR)		
              }
            }           
            imgBalustrady2: file(relativePath: {eq: "page-home/slider-balustrady/2.jpeg" }) {
              childImageSharp {
                gatsbyImageData(webpOptions: {quality: 100}, layout: CONSTRAINED	, formats: WEBP, breakpoints: [300, 500, 750, 1080, 1366, 1920], placeholder: DOMINANT_COLOR)		
              }
            }        
            imgBalustrady3: file(relativePath: {eq: "page-home/slider-balustrady/3.jpeg" }) {
              childImageSharp {
                gatsbyImageData(webpOptions: {quality: 100}, layout: CONSTRAINED	, formats: WEBP, breakpoints: [300, 500, 750, 1080, 1366, 1920], placeholder: DOMINANT_COLOR)		
              }
            }
            imgBalustrady4: file(relativePath: {eq: "page-home/slider-balustrady/4.jpeg" }) {
              childImageSharp {
                gatsbyImageData(webpOptions: {quality: 100}, layout: CONSTRAINED	, formats: WEBP, breakpoints: [300, 500, 750, 1080, 1366, 1920], placeholder: DOMINANT_COLOR)		
              }
            }

            imgBramy1: file(relativePath: {eq: "page-home/slider-bramy/1.jpeg" }) {
              childImageSharp {
                gatsbyImageData(webpOptions: {quality: 100}, layout: CONSTRAINED	, formats: WEBP, breakpoints: [300, 500, 750, 1080, 1366, 1920], placeholder: DOMINANT_COLOR)		
              }
            }           
            imgBramy2: file(relativePath: {eq: "page-home/slider-bramy/2.jpeg" }) {
              childImageSharp {
                gatsbyImageData(webpOptions: {quality: 100}, layout: CONSTRAINED	, formats: WEBP, breakpoints: [300, 500, 750, 1080, 1366, 1920], placeholder: DOMINANT_COLOR)		
              }
            }        
            imgBramy3: file(relativePath: {eq: "page-home/slider-bramy/3.jpeg" }) {
              childImageSharp {
                gatsbyImageData(webpOptions: {quality: 100}, layout: CONSTRAINED	, formats: WEBP, breakpoints: [300, 500, 750, 1080, 1366, 1920], placeholder: DOMINANT_COLOR)		
              }
            }
            imgBramy4: file(relativePath: {eq: "page-home/slider-bramy/4.jpeg" }) {
              childImageSharp {
                gatsbyImageData(webpOptions: {quality: 100}, layout: CONSTRAINED	, formats: WEBP, breakpoints: [300, 500, 750, 1080, 1366, 1920], placeholder: DOMINANT_COLOR)		
              }
            }

            imgMeble1: file(relativePath: {eq: "page-home/slider-meble/1.jpeg" }) {
              childImageSharp {
                gatsbyImageData(webpOptions: {quality: 100}, layout: CONSTRAINED	, formats: WEBP, breakpoints: [300, 500, 750, 1080, 1366, 1920], placeholder: DOMINANT_COLOR)		
              }
            }           
            imgMeble2: file(relativePath: {eq: "page-home/slider-meble/2.jpeg" }) {
              childImageSharp {
                gatsbyImageData(webpOptions: {quality: 100}, layout: CONSTRAINED	, formats: WEBP, breakpoints: [300, 500, 750, 1080, 1366, 1920], placeholder: DOMINANT_COLOR)		
              }
            }        
            imgMeble3: file(relativePath: {eq: "page-home/slider-meble/3.jpeg" }) {
              childImageSharp {
                gatsbyImageData(webpOptions: {quality: 100}, layout: CONSTRAINED	, formats: WEBP, breakpoints: [300, 500, 750, 1080, 1366, 1920], placeholder: DOMINANT_COLOR)		
              }
            }
            imgMeble4: file(relativePath: {eq: "page-home/slider-meble/4.jpeg" }) {
              childImageSharp {
                gatsbyImageData(webpOptions: {quality: 100}, layout: CONSTRAINED	, formats: WEBP, breakpoints: [300, 500, 750, 1080, 1366, 1920], placeholder: DOMINANT_COLOR)		
              }
            }

            imgTarasy1: file(relativePath: {eq: "page-home/slider-tarasy/1.jpeg" }) {
              childImageSharp {
                gatsbyImageData(webpOptions: {quality: 100}, layout: CONSTRAINED	, formats: WEBP, breakpoints: [300, 500, 750, 1080, 1366, 1920], placeholder: DOMINANT_COLOR)		
              }
            }           
            imgTarasy2: file(relativePath: {eq: "page-home/slider-tarasy/2.jpeg" }) {
              childImageSharp {
                gatsbyImageData(webpOptions: {quality: 100}, layout: CONSTRAINED	, formats: WEBP, breakpoints: [300, 500, 750, 1080, 1366, 1920], placeholder: DOMINANT_COLOR)		
              }
            }        
            imgTarasy3: file(relativePath: {eq: "page-home/slider-tarasy/3.jpeg" }) {
              childImageSharp {
                gatsbyImageData(webpOptions: {quality: 100}, layout: CONSTRAINED	, formats: WEBP, breakpoints: [300, 500, 750, 1080, 1366, 1920], placeholder: DOMINANT_COLOR)		
              }
            }
       

  }`